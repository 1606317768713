module Main exposing (main)

import Browser exposing (Document)
import Css exposing (absolute, alignItems, animationDelay, animationDuration, animationIterationCount, animationName, auto, border3, borderBox, borderRadius, boxSizing, center, color, currentColor, displayFlex, fontFamilies, height, infinite, justifyContent, marginBottom, marginLeft, marginRight, opacity, pct, position, px, relative, rgb, sansSerif, sec, solid, vh, width)
import Css.Animations exposing (Keyframes, keyframes, property)
import Css.Global
import File exposing (File)
import Html.Styled exposing (Html, button, div, input, styled, table, td, text, toUnstyled, tr)
import Html.Styled.Attributes exposing (multiple, type_)
import Html.Styled.Events exposing (on, onClick)
import Http
import Json.Decode as D
import Json.Encode as E
import List.Extra
import Platform.Cmd as Cmd
import Task
import Url exposing (Url)
import WebDav


main : Program String Model Msg
main =
    Browser.document
        { init = init
        , view = view
        , update = update
        , subscriptions = \_ -> Sub.none
        }


type Mode
    = Categories
    | Receipts


type Model
    = LoadingReceiptList Url
    | DownloadingReceipts
        { restCount : Int
        , receipts : List Receipt
        , url : Url
        }
    | ReceiptList
        { url : Url
        , mode : Mode
        , receipts : List Receipt
        }
    | Scanning { file : File, url : Url }
    | ShowResult Receipt
    | Failed String
    | Success { receipt : Receipt, url : Url }


type Msg
    = NewReceiptFile (Maybe File)
    | ReceivedResult (Result Http.Error Receipt)
    | FileListing (Result () (List Url))
    | ReceiptDownloaded (Result () String)
    | UploadComplete (Result String Receipt)
    | Back
    | ToggleMode


type alias Receipt =
    { date : String
    , vendorName : String
    , items : List ReceiptItem
    , total : Float
    }


type alias ReceiptItem =
    { description : Maybe String
    , total : Maybe Float
    }


downloadFile : File -> Cmd Msg
downloadFile file =
    Http.post
        { url = "/scan"
        , body =
            Http.multipartBody
                [ Http.filePart "file" file
                ]
        , expect = Http.expectJson ReceivedResult decoderReceipt
        }


decoderReceipt : D.Decoder Receipt
decoderReceipt =
    D.map4 Receipt
        (D.field "date" D.string)
        (D.at [ "vendor", "name" ] D.string)
        (D.field "line_items" (D.list decoderReceiptItem))
        (D.field "total" D.float)


decoderReceiptItem : D.Decoder ReceiptItem
decoderReceiptItem =
    D.map2 ReceiptItem
        (D.field "description" (D.maybe D.string))
        (D.field "total" (D.maybe D.float))


init : String -> ( Model, Cmd Msg )
init url =
    case Url.fromString url of
        Just u ->
            ( LoadingReceiptList u
            , WebDav.findFiles u
                |> Task.map (List.filter (Url.toString >> String.endsWith ".json"))
                |> Task.attempt FileListing
            )

        Nothing ->
            ( Failed "Fehlerhafte Init URL", Cmd.none )


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case ( model, msg ) of
        ( ReceiptList m, NewReceiptFile (Just file) ) ->
            ( Scanning { file = file, url = m.url }, downloadFile file )

        ( Scanning m, ReceivedResult (Ok r) ) ->
            let
                itemsTotal : Float
                itemsTotal =
                    r.items |> List.filterMap .total |> List.sum
            in
            if abs (itemsTotal - r.total) / r.total <= 0.025 then
                let
                    safeDate =
                        String.replace ":" "" r.date

                    ext =
                        String.split "." (File.name m.file)
                            |> List.reverse
                            |> List.head

                    cUrl =
                        ext
                            |> Maybe.andThen
                                (\e ->
                                    Url.toString m.url ++ "/" ++ safeDate ++ " " ++ r.vendorName ++ "." ++ e |> Url.fromString
                                )

                    cUrlJson =
                        Url.toString m.url ++ "/" ++ safeDate ++ " " ++ r.vendorName ++ ".json" |> Url.fromString
                in
                Maybe.map2
                    (\u u2 ->
                        ( Scanning m
                        , WebDav.uploadFile u (Http.fileBody m.file)
                            |> Task.andThen (\_ -> WebDav.uploadFile u2 (Http.jsonBody (encoderFileReceipt r)))
                            |> Task.map (\_ -> r)
                            |> Task.attempt UploadComplete
                        )
                    )
                    cUrl
                    cUrlJson
                    |> Maybe.withDefault ( Failed "Ungültige Bild/Json URL", Cmd.none )

            else
                ( ShowResult r, Cmd.none )

        ( Success { url }, Back ) ->
            init (Url.toString url)

        ( Scanning _, ReceivedResult (Err _) ) ->
            ( Failed "Fehler beim Scannen", Cmd.none )

        ( Scanning m, UploadComplete (Ok r) ) ->
            ( Success { url = m.url, receipt = r }, Cmd.none )

        ( Scanning _, UploadComplete (Err e) ) ->
            ( Failed <| "Upload Bild/Json fehlgeschlagen: " ++ e, Cmd.none )

        ( LoadingReceiptList u, FileListing (Ok l) ) ->
            if List.length l > 0 then
                ( DownloadingReceipts
                    { restCount = List.length l
                    , receipts = []
                    , url = u
                    }
                , l
                    |> List.map WebDav.downloadFile
                    |> List.map (Task.attempt ReceiptDownloaded)
                    |> Cmd.batch
                )

            else
                ( ReceiptList { mode = Receipts, url = u, receipts = [] }, Cmd.none )

        ( LoadingReceiptList _, FileListing (Err _) ) ->
            ( Failed "Fehler beim Abruf der Rechnungen", Cmd.none )

        ( DownloadingReceipts m, ReceiptDownloaded (Ok r) ) ->
            D.decodeString decoderFileReceipt r
                |> Result.map
                    (\x ->
                        if m.restCount == 1 then
                            ( ReceiptList { mode = Receipts, url = m.url, receipts = x :: m.receipts }, Cmd.none )

                        else
                            ( DownloadingReceipts
                                { m
                                    | restCount = m.restCount - 1
                                    , receipts = x :: m.receipts
                                }
                            , Cmd.none
                            )
                    )
                |> Result.withDefault ( Failed "Fehlerhafter Rechnungsinhalt", Cmd.none )

        ( DownloadingReceipts _, ReceiptDownloaded (Err _) ) ->
            ( Failed "Fehlerhafte Rechnung geladen", Cmd.none )

        ( ReceiptList m, ToggleMode ) ->
            ( ReceiptList
                { m
                    | mode =
                        case m.mode of
                            Categories ->
                                Receipts

                            Receipts ->
                                Categories
                }
            , Cmd.none
            )

        _ ->
            ( model, Cmd.none )


decoderFileReceipt : D.Decoder Receipt
decoderFileReceipt =
    D.map4 Receipt
        (D.field "date" D.string)
        (D.field "vendorName" D.string)
        (D.field "items" (D.list decoderFileReceiptItem))
        (D.field "total" D.float)


decoderFileReceiptItem : D.Decoder ReceiptItem
decoderFileReceiptItem =
    D.map2 ReceiptItem
        (D.field "description" (D.maybe D.string))
        (D.field "total" (D.maybe D.float))


encoderFileReceipt : Receipt -> E.Value
encoderFileReceipt r =
    E.object
        [ ( "date", E.string r.date )
        , ( "vendorName", E.string r.vendorName )
        , ( "items", E.list encoderFileReceiptItem r.items )
        , ( "total", E.float r.total )
        ]


encoderFileReceiptItem : ReceiptItem -> E.Value
encoderFileReceiptItem ri =
    E.object
        [ ( "description", ri.description |> Maybe.map E.string |> Maybe.withDefault E.null )
        , ( "total", ri.total |> Maybe.map E.float |> Maybe.withDefault E.null )
        ]


view : Model -> Document Msg
view model =
    { title = "Receipt Scanner"
    , body =
        [ bodyStyle |> Css.Global.body |> List.singleton |> Css.Global.global
        , viewBody model
        ]
            |> List.map toUnstyled
    }


bodyStyle : List Css.Style
bodyStyle =
    [ fontFamilies [ "Arial", .value sansSerif ]
    , displayFlex
    , justifyContent center
    , height (vh 100)
    ]


viewBody : Model -> Html Msg
viewBody model =
    case model of
        LoadingReceiptList _ ->
            viewLoadingIndicator "Lade Rechnungen"

        Scanning _ ->
            viewLoadingIndicator "Bitte warten"

        ShowResult receipt ->
            viewReceipt receipt

        ReceiptList m ->
            div []
                [ input
                    [ type_ "file"
                    , multiple False
                    , on "change" (D.map NewReceiptFile filesDecoder)
                    ]
                    []
                , button [ onClick ToggleMode ]
                    [ text <|
                        case m.mode of
                            Categories ->
                                "Liste"

                            Receipts ->
                                "Kategorien"
                    ]
                , text <|
                    case m.mode of
                        Categories ->
                            m.receipts
                                |> List.concatMap .items
                                |> List.map (.total >> Maybe.withDefault 0)
                                |> List.sum
                                |> String.fromFloat

                        Receipts ->
                            m.receipts
                                |> List.map .total
                                |> List.sum
                                |> String.fromFloat
                , case m.mode of
                    Receipts ->
                        table [] (viewReceiptList m.receipts)

                    Categories ->
                        table [] (viewCategoryList m.receipts)
                ]

        Failed str ->
            text str

        DownloadingReceipts m ->
            text <| "Lade Rechnungen, noch: " ++ String.fromInt m.restCount

        Success { receipt } ->
            div []
                [ text "Erfolg!"
                , button [ onClick Back ]
                    [ text "Zurück"
                    ]
                , viewReceipt receipt
                ]


viewReceiptList : List Receipt -> List (Html Msg)
viewReceiptList rs =
    rs |> List.map viewReceiptListRow


viewCategoryList : List Receipt -> List (Html Msg)
viewCategoryList rs =
    rs
        |> List.concatMap .items
        |> List.map (\i -> { item = i, category = findCategory i })
        |> List.sortBy (.category >> Maybe.withDefault "ZZZ")
        |> List.Extra.groupWhile (\x1 x2 -> x1.category == x2.category)
        |> List.map
            (\g ->
                let
                    ( head, tail ) =
                        g
                in
                case head.category of
                    Just x ->
                        [ tableRow x (head :: tail |> List.map (\k -> k.item.total |> Maybe.withDefault 0) |> List.sum |> String.fromFloat) ]

                    Nothing ->
                        head
                            :: tail
                            |> List.map (\y -> tableRow ("?" ++ (y.item.description |> Maybe.withDefault "")) (y.item.total |> Maybe.withDefault 0 |> String.fromFloat))
            )
        |> List.concatMap identity


viewReceiptListRow : Receipt -> Html Msg
viewReceiptListRow r =
    tableRow3 r.date r.vendorName (String.fromFloat r.total)


viewReceipt : Receipt -> Html Msg
viewReceipt receipt =
    table []
        ([ tableRow "Datum" receipt.date
         , tableRow "Geschäft" receipt.vendorName
         , tableRow "\u{00A0}" ""
         ]
            ++ (receipt.items |> List.map viewReceiptItem)
            ++ [ tableRow "\u{00A0}" ""
               , tableRow "Gesamt" (String.fromFloat receipt.total)
               , let
                    itemsTotal =
                        receipt.items |> List.filterMap .total |> List.sum
                 in
                 if itemsTotal /= receipt.total then
                    styled div [ color (rgb 255 0 0) ] [] [ text <| "Summe Einzeln: " ++ String.fromFloat itemsTotal ]

                 else
                    text ""
               ]
        )


viewReceiptItem : ReceiptItem -> Html Msg
viewReceiptItem item =
    tableRow (item.description |> Maybe.withDefault "(Leerzeile)") (Maybe.map String.fromFloat item.total |> Maybe.withDefault "")


tableRow3 : String -> String -> String -> Html Msg
tableRow3 c1 c2 c3 =
    tr []
        [ td [] [ text c1 ]
        , td [] [ text c2 ]
        , td [] [ text c3 ]
        ]


tableRow : String -> String -> Html Msg
tableRow key value =
    tr []
        [ td [] [ text key ]
        , td [] [ text value ]
        ]


filesDecoder : D.Decoder (Maybe File)
filesDecoder =
    D.at [ "target", "files" ] (D.list File.decoder)
        |> D.map List.head


viewLoadingIndicator : String -> Html Msg
viewLoadingIndicator txt =
    styled div
        [ displayFlex
        , alignItems center
        ]
        []
        [ div []
            [ styled div
                [ color (rgb 0x1C 0x4C 0x5B)
                , boxSizing borderBox
                , position relative
                , width (px 80)
                , height (px 80)
                , marginLeft auto
                , marginRight auto
                , marginBottom (px 10)
                ]
                []
                [ styled div
                    loadingIndicatorStyle
                    []
                    []
                , styled div
                    (loadingIndicatorStyle
                        ++ [ animationDelay (sec -0.5)
                           ]
                    )
                    []
                    []
                ]
            , text txt
            ]
        ]


loadingIndicatorStyle : List Css.Style
loadingIndicatorStyle =
    [ boxSizing borderBox
    , position absolute
    , border3 (px 4) solid currentColor
    , opacity (Css.num 1)
    , borderRadius (pct 50)
    , animationDuration (sec 1)
    , animationIterationCount infinite
    , animationName loadingIndicatorKeyFrames
    , animationCubicBezier 0 0.2 0.8 1
    ]


animationCubicBezier : Float -> Float -> Float -> Float -> Css.Style
animationCubicBezier float float2 float3 float4 =
    Css.property "animation-timing-function"
        ("cubic-bezier("
            ++ String.fromFloat float
            ++ " , "
            ++ String.fromFloat float2
            ++ " , "
            ++ String.fromFloat float3
            ++ " , "
            ++ String.fromFloat float4
            ++ ")"
        )


loadingIndicatorKeyFrames : Keyframes {}
loadingIndicatorKeyFrames =
    keyframes
        [ ( 0
          , [ property "top" "36px"
            , property "left" "36px"
            , property "width" "8px"
            , property "height" "8px"
            , property "opacity" "0"
            ]
          )
        , ( 4
          , [ property "top" "36px"
            , property "left" "36px"
            , property "width" "8px"
            , property "height" "8px"
            , property "opacity" "0"
            ]
          )
        , ( 5
          , [ property "top" "36px"
            , property "left" "36px"
            , property "width" "8px"
            , property "height" "8px"
            , property "opacity" "1"
            ]
          )
        , ( 100
          , [ property "top" "0"
            , property "left" "0"
            , property "width" "80px"
            , property "height" "80px"
            , property "opacity" "0"
            ]
          )
        ]


findCategory : ReceiptItem -> Maybe String
findCategory item =
    case matchers |> List.filter (\t -> Tuple.second t item) of
        [ c ] ->
            Just <| Tuple.first c

        _ ->
            Nothing


matchers : List ( String, ReceiptItem -> Bool )
matchers =
    [ ( "Obst & Gemüse"
      , or <|
            [ itemContains "Gurke"
            , itemContains "Banane"
            , itemContains "Zucchini"
            , itemContains "Rucola"
            , itemContains "Apfel"
            , itemContains "Äpfel"
            , itemContains "Erdbeeren"
            , itemContains "Karotten"
            , itemContains "Avocado"
            , itemContains "Orange"
            , itemContains "Lauch"
            , itemContains "Paprika"
            , itemContains "Salat"
            , itemContains "Drillinge"
            , itemContains "Himbeeren"
            , itemContains "Heidelbeeren"
            , itemContains "Fenchel"
            , itemContains "Radieschen"
            , itemContains "Obst"
            , itemContains "Rispen"
            , itemContains "Zitronen"
            , itemContains "Sonnentomaten"
            , itemContains "Cocktailtom"
            , itemContains "Spinat"
            , itemContains "Kopfsal"
            , itemContains "Mais"
            ]
      )
    , ( "Milchprodukte & Eier"
      , or <|
            [ itemContains "Quark"
            , itemContains "Milch"
            , itemContains "Eier"
            , itemContains "Gouda"
            , itemContains "Alpro So"
            , itemContains "Skyr"
            , itemContains "GRANA PADANO"
            , itemContains "Ziegenk"
            , itemContains "Alpro Hafer"
            , itemContains "BIO And Griechischer"
            , itemContains "Mozzarella Minis"
            , itemContains "Frischkase"
            , itemContains "Frischkäse"
            , itemContains "H-Mich"
            , itemContains "Aloro Himb"
            , itemContains "Bio-Koer. Frischk."
            , itemContains "Reggiano"
            , itemContains "m:lch"
            , itemContains "Grillweichk"
            , itemContains "Koer. Frischk"
            ]
      )
    , ( "Pasta, Brot & Co"
      , or <|
            [ itemContains "Spaghetti"
            , itemContains "Pasta"
            , itemContains "Oliven"
            , itemContains "Nudel"
            , itemContains "Sandwich"
            , itemContains "Baguette"
            , itemContains "TOMATENS KINDER"
            , itemContains "KNUSPERBROT"
            , itemContains "Dinkelbrötchen"
            ]
      )
    , ( "Fleisch, Tofu & Wurst"
      , or <|
            [ itemContains "Prosciutto"
            , itemContains "Hähn."
            , itemContains "SALAMI"
            , itemContains "Tofu"
            , itemContains "Nurnberger Rostbra"
            , itemContains "Wurst"
            , itemContains "Hack"
            ]
      )
    , ( "Bier^& Wasser"
      , or <|
            [ itemContains "Pils"
            , itemContains "Wa med."
            ]
      )
    , ( "Müsli & Co"
      , or <|
            [ itemContains "Musli"
            , itemContains "Müsli"
            , itemContains "Haferflocken"
            , itemContains "Mandel"
            , itemContains "Nuss"
            ]
      )
    , ( "Süßes & Eis"
      , or <|
            [ itemContains "Riegel"
            , itemContains "Schogetten"
            , itemContains "Eis"
            , itemContains "KINDER BUENO"
            , itemContains "Cookie"
            ]
      )
    , ( "Windel"
      , or <|
            [ itemContains "Pants"
            , itemContains "Windel"
            ]
      )
    , ( "Pfand"
      , or <|
            [ itemContains "Pfand"
            ]
      )
    ]


or : List (ReceiptItem -> Bool) -> (ReceiptItem -> Bool)
or lst =
    \r -> lst |> List.map (\i -> i r) |> List.foldl (||) False


itemContains : String -> ReceiptItem -> Bool
itemContains str =
    .description >> Maybe.withDefault "" >> String.toLower >> String.contains (String.toLower str)
